import request from './request'

const httpApi = {}

httpApi.submit = function(url, method, data) {
  return request({
    url: '/api/'+url,
    method: method,
    data: data
  })
}
httpApi.post = function(url, data) {
  return request({
    url: '/api/'+url,
    method: 'post',
    data: data
  })
}
httpApi.postParams = function(url, params) {
  return request({
    url: '/api/'+url,
    method: 'post',
    params: params
  })
}

httpApi.get = function(url, params) {
  // console.log(params)
  return request({
    url: '/api/'+url,
    method: 'get',
    params: params
  })
}
httpApi.getJson = function(url, params) {
  // console.log(params)
  return request({
    url: '/api/'+url,
    method: 'get',
    params: params,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

httpApi.delete = function(url, params) {
  return request({
    url: '/api/'+url,
    method: 'delete',
    params: params
  })
}

httpApi.put = function(url, params) {
  return request({
    url: '/api/'+url,
    method: 'put',
    params: params
  })
}

httpApi.putBody = function(url, data) {
  return request({
    url: '/api/'+url,
    method: 'put',
    data: data
  })
}

httpApi.uploadFile = function(url, data) {
  return request({
    url: '/api/'+url,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export default httpApi

