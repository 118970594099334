import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import httpApi from "@/api/httpApi";

const app = createApp(App);
app.use(router);
app.use(httpApi);

app.mount('#app');

// import VConsole from 'vconsole'
// new VConsole()