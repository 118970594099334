import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import { Toast, Dialog } from 'vant'
// import NProgress from 'nprogress' // progress bar
// import store from '@/store'
// import tool from '../utils/tool'
// import Vue from 'vue'

// create an axios instance
console.log(process.env.VUE_APP_BASE_API, 'baseURL')
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  timeout: 30000, // request timeout
  withCredentials: false,
  validateStatus: function(status) {
    return status >= 200 && status < 300 // default
  }
})

service.interceptors.request.use(
  config => {
    // config.headers['token'] = 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VySWQiOiI5MDAxNzA1MjkxNzEzNTE0MDY3Iiwic3ViIjoiOTAwMTcwNTI5MTcxMzUxNDA2NyIsImlhdCI6MTYzMTk1OTAxNSwiZXhwIjoxNjM0NTUxMDE1fQ.opO2RkwnwN_O7ESVXUSymcXrxBzlDUCLN1kMbQ7rUJzlqoZqK5xHS6Faftd_H-atLGdoFqC4WsWwUug5rtRqVg'
    // config.headers['token'] = tool.handleSessionStorage('get', 'token')
    config.headers['x-api-key'] = 'CgwuncXx0EF7POYL95ZpFmkWlosa/axB2cEzwahlW+q0VWyHc2BX/jJOKO8bcMaN962wKTVUlhCXaawshaYImA=='
    config.headers['appid'] = 'PCWEB'
    // }
    var qs = require('qs')
    // if (config.method === 'get') {
    // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
    config.paramsSerializer = function(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
    // }
    return config
  },
  error => {
    console.log(error) // for debug
    return error
  }
)
// let loadingInstance // 遮罩对象
let requestQueue = [] // 请求队列
// 入列
function addQueue(resolve) {
  // if (!requestQueue || requestQueue.length <= 0) {
  //   loadingInstance = Loading.service({ text: '数据加载中...' })
  // }
  requestQueue.push(resolve)
}
// 清空队列
function clearQueue() {
  if (requestQueue && requestQueue.length > 0) {
    requestQueue.forEach((resolve) => {
      resolve()
    })
    requestQueue = []
    // loadingInstance.close()
  }
}
service.interceptors.response.use(
  response => {
    return new Promise((resolve, reject) => {
      // NProgress.done()
      const result = response.data
      if (result.code === 1005) {
        messageError('权限不足！')
        reject()
      } else if (result.code === 5000) {
        logOut('您的账号已在其他设备登录，请重新登录')
        reject()
      } else {
        resolve(response)
      }
    })
    // return response
  },
  error => {
    console.log('队列数量 = %s', requestQueue.length)
    // 新建一个promise 阻塞response
    return new Promise((resolve) => {
      if (requestQueue.length === 0) {
        responseError(error)
      }
      addQueue(resolve)
    })
  }
)

function responseError(error) {
  if (error.response) {
    const status = error.response.status
    switch (status) {
      case 400:
        messageError('参数绑定错误')
        break
      case 401:
        // eslint-disable-next-line no-case-declarations
        const result = error.response.data
        if (result.code === 1002 || result.code === 1003 || result.code === 1004) {
          logOut('您的账号已超时,请重新登录')
        } else if (result.code === 5000) {
          logOut('您的账号已在其他设备登录，请重新登录')
        } else {
          messageError(result.message)
        }
        break
      case 404:
        messageError('无效的请求地址')
        break
      default:
        messageError('服务器繁忙，请稍后再试')
        break
    }
  }
}

// 错误信息 释放消息锁
function messageError(msg) {
  Toast.fail({
    message: msg,
    onClose: () => {
      clearQueue()
    }
  });
}

function logOut(message) {
  Dialog.alert({
    title: '登录失效',
    message: message,
    confirmButtonText: '重新登录',
    theme: 'round-button',
  }).then(() => {
    clearQueue()
    // return store.dispatch('user/logout')
  }).catch((error) => {
    console.log(error)
  });
}

export default service

