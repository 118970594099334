import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'login',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/login/index'),
    meta: {
      title: '绑定账户',
    }
  },
  {
    name: 'index',
    path: '/index',
    component: () => import('./view/index'),
    meta: {
      title: '查询订单信息 - 斯比特物流',
    }
  },
  {
    name: 'go',
    path: '/go',
    component: () => import('./view/login/go'),
    meta: {
      title: '绑定成功',
    }
  }
];

const router = createRouter({
  mode: 'history',
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
